import React, { useState } from 'react';
import styled from 'styled-components';
import {
  breakpointQuery,
  standardColours,
  brandColours,
  fontWeights,
  fontSize,
  standardTransition,
  visuallyHidden,
} from '../utilities';
import Animation from './Animation';
import SectionHeader from './SectionHeader';
import Container from './Container';
import { getAnchorId } from '../utils/AnchorId';

const StyledBestModuleCalculator = styled.section``;

const StyledBestModuleCalculatorHeader = styled(SectionHeader)`
  margin-bottom: 30px;

  ${breakpointQuery.small`
    margin-bottom: 40px;
  `}

  ${breakpointQuery.large`
    margin-bottom: 50px;
  `}

  ${breakpointQuery.xxlarge`
    margin-bottom: 60px;
  `}
`;

const StyledBestModuleCalculatorForm = styled.form`
  display: grid;
  gap: 20px;

  ${breakpointQuery.small`
    gap: 25px;
  `}

  ${breakpointQuery.smedium`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${breakpointQuery.large`
    gap: 30px;
  `}
`;

const StyledBestModuleCalculatorQuestion = styled.fieldset`
  margin: 0;
  padding: 20px;
  background-color: ${standardColours.lightestGrey};
  border: none;
  border-radius: 20px;

  ${breakpointQuery.small`
    padding: 25px;
  `}

  ${breakpointQuery.large`
    padding: 30px;
  `}

  &:not(:last-of-type) {
    ${breakpointQuery.smedium`
      grid-row: span 2;
    `}
  }
`;

const StyledBestModuleCalculatorHeading = styled.legend`
  margin-bottom: 15px;
  padding: 0;
  float: left;
  width: 100%;
  font-weight: ${fontWeights.semibold};
  ${fontSize(17)};

  ${breakpointQuery.large`
    ${fontSize(18)};
  `}
`;

// moved up for checked styling
const StyledBestModuleCalculatorName = styled.strong`
  font-weight: ${fontWeights.medium};
  ${fontSize(17)};
  line-height: 1.6;

  ${breakpointQuery.large`
    ${fontSize(18)};
  `}

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 26px;
    width: 26px;
    border: 2px solid ${brandColours.septenary};
    border-radius: 2px;
    transition: ${standardTransition('background-color')};
  }

  &:after {
    content: '';
    position: absolute;
    top: 4px;
    left: 9px;
    height: 14px;
    width: 8px;
    border: 3px solid ${standardColours.transparent};
    border-top: none;
    border-left: none;
    border-radius: 1px;
    transform: rotate(45deg);
    transition: ${standardTransition('border-color')};
  }
`;

const StyledBestModuleCalculatorOption = styled.label`
  position: relative;
  display: block;
  margin: 10px 0;
  padding-left: 40px;
  float: left;
  width: 100%;
  cursor: pointer;

  &:hover {
    ${StyledBestModuleCalculatorName} {
      &:after {
        border-color: ${brandColours.septenary};
      }
    }
  }
`;

const StyledBestModuleCalculatorInput = styled.input`
  ${visuallyHidden()};

  &:checked + ${StyledBestModuleCalculatorName} {
    &:after {
      border-color: ${brandColours.septenary};
    }
  }
`;

const StyledBestModuleCalculatorDescription = styled.span`
  display: block;
  margin-top: 2px;
  ${fontSize(14)};
  line-height: 1.5;
`;

const StyledResult = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  font-weight: ${fontWeights.semibold};
  ${fontSize(17)};
  line-height: 1.5;
  text-align: center;
  background-color: ${standardColours.lightestGrey};
  border: 6px solid ${brandColours.septenary};
  border-radius: 20px;

  ${breakpointQuery.small`
    padding: 25px;
  `}

  ${breakpointQuery.large`
    padding: 30px;
    ${fontSize(18)};
  `}
`;

const BestModuleCalculator = ({ heading, text }) => {
  const procurementTeamMaturityStages = [
    {
      name: 'Tactical',
      description: 'Basic sourcing Order processing',
      score: 0,
    },
    {
      name: 'Strategic Sourcing',
      description: 'Spend Analytics Contract Management',
      score: 0,
    },
    {
      name: 'Category Strategy',
      description: 'Category Development Supplier Management',
      score: 0,
    },
    {
      name: 'Leadership',
      description: 'Business Partnering Demand Management',
      score: 0,
    },
  ];

  const spendDataMaturityStages = [
    { name: 'Ad-hoc analysis', description: 'Mainly spreadsheets', score: 10 },
    {
      name: 'Monthly standard reports',
      description: 'Elements of BI',
      score: 5,
    },
    {
      name: 'Supplier & Category analysis',
      description: 'Business-wide BI tools',
      score: 0,
    },
    {
      name: 'Predictive analysis',
      description: 'Advanced Data Analytics Platform',
      score: 0,
    },
  ];

  const procurementTeamSizes = [
    { name: '1-5', score: 1 },
    { name: '6-15', score: 2 },
    { name: '16+', score: 3 },
  ];

  const [totalScore, setTotalScore] = useState(11);

  const calculateBestModule = e => {
    const formData = new FormData(e.currentTarget);
    const procurementTeamMaturityStage = formData.get(
      'procurement-team-maturity-stage'
    );
    const spendDataMaturityStage = formData.get('spend-data-maturity-stage');
    const procurementTeamSize = formData.get('procurement-team-size');

    const totalScore =
      parseInt(procurementTeamMaturityStage) +
      parseInt(spendDataMaturityStage) +
      parseInt(procurementTeamSize);

    setTotalScore(totalScore);
  };

  return (
    <StyledBestModuleCalculator id={getAnchorId(heading)}>
      <Animation>
        <StyledBestModuleCalculatorHeader heading={heading} text={text} />
        <Container>
          <StyledBestModuleCalculatorForm
            name="form"
            onChange={e => calculateBestModule(e)}
          >
            <StyledBestModuleCalculatorQuestion>
              <StyledBestModuleCalculatorHeading>
                Procurement team maturity stage
              </StyledBestModuleCalculatorHeading>
              {procurementTeamMaturityStages.map(
                ({ name, description, score }, i) => (
                  <StyledBestModuleCalculatorOption key={i}>
                    <StyledBestModuleCalculatorInput
                      type="radio"
                      name="procurement-team-maturity-stage"
                      value={score}
                      defaultChecked={i === 0}
                    />
                    <StyledBestModuleCalculatorName>
                      {name}
                    </StyledBestModuleCalculatorName>
                    <StyledBestModuleCalculatorDescription>
                      {description}
                    </StyledBestModuleCalculatorDescription>
                  </StyledBestModuleCalculatorOption>
                )
              )}
            </StyledBestModuleCalculatorQuestion>
            <StyledBestModuleCalculatorQuestion>
              <StyledBestModuleCalculatorHeading>
                Spend data maturity stage
              </StyledBestModuleCalculatorHeading>
              {spendDataMaturityStages.map(
                ({ name, description, score }, i) => (
                  <StyledBestModuleCalculatorOption key={i}>
                    <StyledBestModuleCalculatorInput
                      type="radio"
                      name="spend-data-maturity-stage"
                      value={score}
                      defaultChecked={i === 0}
                    />
                    <StyledBestModuleCalculatorName>
                      {name}
                    </StyledBestModuleCalculatorName>
                    <StyledBestModuleCalculatorDescription>
                      {description}
                    </StyledBestModuleCalculatorDescription>
                  </StyledBestModuleCalculatorOption>
                )
              )}
            </StyledBestModuleCalculatorQuestion>
            <StyledBestModuleCalculatorQuestion>
              <StyledBestModuleCalculatorHeading>
                Size of procurement team
              </StyledBestModuleCalculatorHeading>
              {procurementTeamSizes.map(({ name, score }, i) => (
                <StyledBestModuleCalculatorOption key={i}>
                  <StyledBestModuleCalculatorInput
                    type="radio"
                    name="procurement-team-size"
                    value={score}
                    defaultChecked={i === 0}
                  />
                  <StyledBestModuleCalculatorName>
                    {name}
                  </StyledBestModuleCalculatorName>
                </StyledBestModuleCalculatorOption>
              ))}
            </StyledBestModuleCalculatorQuestion>
            <StyledResult>
              {totalScore > 11
                ? "Worth starting on 'Growth' and move towards 'Enterprise' later"
                : totalScore > 8
                ? "Best package looks like 'Start'"
                : totalScore > 6
                ? "You have some tools, but let's trial the 'Growth' plan"
                : totalScore > 5
                ? "Small team, some tools, let's trial the 'Start' plan"
                : totalScore > 0
                ? 'Looks like your team is well set with tech, but happy to speak further if you are looking for something new.'
                : ''}
            </StyledResult>
          </StyledBestModuleCalculatorForm>
        </Container>
      </Animation>
    </StyledBestModuleCalculator>
  );
};

export default BestModuleCalculator;
