import React from 'react';
import styled from 'styled-components';
import {
  breakpointQuery,
  standardColours,
  brandColours,
  sectionMargins,
} from '../utilities';
import Animation from './Animation';
import Container from './Container';
import Heading from './Heading';
import HTMLContent from './HTMLContent';
import FixedImg from './FixedImg';

const StyledImageVideoContent = styled.section`
  ${sectionMargins()};

  ${({ highlight }) => {
    if (highlight) {
      return `
        position: relative;
        padding: 80px 0;
        overflow: hidden;
        background-color: ${standardColours.lighterGrey};

        &:before,
        &:after {
          content: '';
          position: absolute;
          right: -10%;
          left: -10%;
          height: 80px;
          background-color: ${standardColours.white};
          border-radius: 50%;
        }

        &:before {
          top: 0;
          transform: translateY(-50%) rotate(1deg);
        }

        &:after {
          bottom: 0;
          transform: translateY(50%) rotate(1deg);
        }
      `;
    }
  }}
`;

const StyledImageVideoContentInner = styled.div`
  ${breakpointQuery.small`
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${({ flip }) => {
      if (flip) {
        return `
          flex-direction: row-reverse;
        `;
      }
    }}
  `}
`;

const StyledImageVideoContentBlock = styled.div`
  ${breakpointQuery.small`
    width: calc(50% - 20px);
  `}

  ${breakpointQuery.large`
    width: calc(50% - 30px);
  `}
`;

const StyledImageVideoContentHeading = styled(Heading)`
  &:before {
    content: '';
    display: block;
    margin-bottom: 20px;
    height: 4px;
    width: 75%;
    background-color: ${brandColours.tertiary};
  }
`;

const StyledImageVideoContentVideoWrap = styled.div`
  position: relative;
  width: 100%;
  border: 10px solid ${standardColours.lighterGrey};
  border-top-width: 26px;
  border-bottom-width: 18px;
  border-radius: 10px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: -14px;
    display: block;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    opacity: 0.5;
  }

  &:before {
    left: 2px;
    background-color: red;
  }

  &:after {
    left: 14px;
    background-color: green;
  }
`;

const StyledImageVideoContentVideo = styled.video`
  display: block;
  width: 100%;
`;

const ImageVideoContent = ({
  heading,
  content,
  image,
  video: { url, basename } = '',
  flip,
  highlight,
}) => {
  return (
    <StyledImageVideoContent id={basename} highlight={highlight}>
      <Animation>
        <Container>
          <StyledImageVideoContentInner flip={flip}>
            <StyledImageVideoContentBlock>
              <StyledImageVideoContentHeading>
                {heading}
              </StyledImageVideoContentHeading>
              <HTMLContent content={content} />
            </StyledImageVideoContentBlock>
            <StyledImageVideoContentBlock>
              {image && <FixedImg image={image} />}
              {url && (
                <StyledImageVideoContentVideoWrap>
                  <StyledImageVideoContentVideo autoplay muted controls loop>
                    <source src={url} type="video/mp4" />
                  </StyledImageVideoContentVideo>
                </StyledImageVideoContentVideoWrap>
              )}
            </StyledImageVideoContentBlock>
          </StyledImageVideoContentInner>
        </Container>
      </Animation>
    </StyledImageVideoContent>
  );
};

export default ImageVideoContent;
