import React from 'react';
import styled from 'styled-components';
import { sectionMargins } from '../utilities';
import Animation from './Animation';
import Container from './Container';
import HTMLContent from './HTMLContent';
import ButtonLink from './ButtonLink';

const StyledContent = styled.section`
  ${sectionMargins()};
`;

const StyledContentButtonLinks = styled.div`
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 15px;
`;

const Content = ({ content, links }) => {
  return (
    <StyledContent>
      <Animation>
        <Container narrow={true}>
          <HTMLContent content={content} />
          {links && links.length > 0 && (
            <StyledContentButtonLinks>
              {links.map(({ url, text }, id) => (
                <ButtonLink key={id} to={url}>
                  {text}
                </ButtonLink>
              ))}
            </StyledContentButtonLinks>
          )}
        </Container>
      </Animation>
    </StyledContent>
  );
};

export default Content;
