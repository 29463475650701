import React from 'react';
import styled from 'styled-components';
import { sectionMargins } from '../utilities';
import FixedImg from './FixedImg';

const StyledFullWidthImage = styled.section`
  ${sectionMargins()};
`;

const FullWidthImage = ({ image }) => {
  return (
    <StyledFullWidthImage>
      <FixedImg image={image} />
    </StyledFullWidthImage>
  );
};

export default FullWidthImage;
