import React from 'react';
import styled from 'styled-components';
import {
  breakpointQuery,
  standardColours,
  brandColours,
  fontSize,
  sectionMargins,
} from '../utilities';
import Animation from './Animation';
import Container from './Container';
import ButtonLink from './ButtonLink';

const StyledDemoCallToAction = styled.article`
  ${sectionMargins()};
  padding: 30px 0;
  background-color: ${brandColours.tertiary};
`;

const StyledDemoCallToActionInner = styled.div`
  ${breakpointQuery.small`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}
`;

const StyledDemoCallToActionHeading = styled.h3`
  color: ${standardColours.white};
  ${fontSize(23)};

  ${breakpointQuery.tiny`
    ${fontSize(26)};
  `}

  ${breakpointQuery.medium`
    ${fontSize(29)};
  `}

  ${breakpointQuery.large`
    ${fontSize(32)};
  `}
`;

const StyledDemoCallToActionText = styled.p`
  margin: 20px 0;
  color: ${standardColours.white};
  line-height: 2;

  ${breakpointQuery.small`
    margin: 0 30px;
  `}

  ${breakpointQuery.medium`
    ${fontSize(18)};
  `}
`;

const StyledDemoCallToActionButton = styled(ButtonLink)`
  white-space: nowrap;
`;

const DemoCallToAction = () => {
  return (
    <StyledDemoCallToAction>
      <Animation>
        <Container>
          <StyledDemoCallToActionInner>
            <StyledDemoCallToActionHeading>
              Contact us for your dashboard demo
            </StyledDemoCallToActionHeading>
            <StyledDemoCallToActionText>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut nec
              tellus porta, cursus diam quis, pulvinar dolor.
            </StyledDemoCallToActionText>
            <StyledDemoCallToActionButton
              to="/contact/"
              colour={standardColours.darkestGrey}
              backgroundcolour={standardColours.white}
            >
              Book demo
            </StyledDemoCallToActionButton>
          </StyledDemoCallToActionInner>
        </Container>
      </Animation>
    </StyledDemoCallToAction>
  );
};

export default DemoCallToAction;
