import React from 'react';
import styled from 'styled-components';
import {
  breakpointQuery,
  standardColours,
  brandColours,
  fontSize,
  fontWeights,
} from '../utilities';
import Animation from './Animation';
import FixedImg from './FixedImg';

const StyledTeamProfile = styled.article`
  margin: 20px 0;
  background-color: ${standardColours.white};

  ${breakpointQuery.smedium`
    display: flex;

    ${({ even }) => {
      if (even) {
        return `
          flex-direction: row-reverse;
        `;
      }
    }}
  `}
`;

const StyledTeamProfileBlockOne = styled.div`

  ${breakpointQuery.tsmall`
    display: flex;
  `}

  ${breakpointQuery.smedium`
    flex-direction: column;
    width: 38%;
  `}

  ${breakpointQuery.mlarge`
    width: 36%;
  `}

  ${breakpointQuery.large`
    width: 34%;
  `}
`;

const StyledTeamProfileImageWrap = styled.div`
  ${breakpointQuery.tsmall`
    width: 60%;
  `}

  ${breakpointQuery.smedium`
    width: 100%;
  `}
`;

const StyledTeamProfileValues = styled.div`
  padding: 16px 20px;
  width: 100%;
  background-color: ${brandColours.primary};

  ${breakpointQuery.tsmall`
    display: flex;
    align-items: center;
  `};

  ${breakpointQuery.smedium`
    height: 100%;
  `}

  ${breakpointQuery.medium`
    padding: 30px;
  `}

  ${breakpointQuery.large`
    padding: 40px;
  `}
`;

const StyledTeamProfileValuesText = styled.p`
  color: ${standardColours.white};
  ${fontSize(20)};
  line-height: 1.4;

  ${breakpointQuery.tiny`
    ${fontSize(22)};
  `}

  ${breakpointQuery.medium`
    ${fontSize(24)};
  `}

  ${breakpointQuery.large`
    ${fontSize(26)};
  `}
`;

const StyledTeamProfileBlockTwo = styled.div`
  padding: 20px;

  ${breakpointQuery.smedium`
    width: 62%;
  `}

  ${breakpointQuery.medium`
    padding: 30px;
  `}

  ${breakpointQuery.mlarge`
    width: 64%;
  `}

  ${breakpointQuery.large`
    padding: 40px;
    width: 66%;
  `}
`;

const StyledTeamProfileHeading = styled.h3`
  ${fontSize(20)};

  ${breakpointQuery.tiny`
    ${fontSize(22)};
  `}

  ${breakpointQuery.medium`
    ${fontSize(24)};
  `}

  ${breakpointQuery.large`
    ${fontSize(26)};
  `}

  &:before {
    content: '';
    display: block;
    margin-bottom: 20px;
    height: 4px;
    width: 60px;
    background-color: ${brandColours.secondary};

    ${breakpointQuery.large`
      margin-bottom: 26px;
    `}
  }
`;

const StyledTeamProfileText = styled.p`
  margin-top: 16px;
  ${fontSize(15)}
  line-height: 2;

  ${({ highlighted }) => {
    if (highlighted) {
      return `
        font-weight: ${fontWeights.regular};
        font-style: italic;
      `;
    }
  }}

  ${({ backgroundcolour }) => {
    if (backgroundcolour) {
      return `
        display: inline-block;
        padding: 2px 10px;
        color: ${standardColours.white};
        background-color: ${brandColours.secondary};
      `;
    }
  }}

  ${breakpointQuery.large`
    margin-top: 22px;
  `}
`;

const TeamProfile = ({
  data: { image, values, name, role, biography, quote },
  even,
}) => {
  return (
    <Animation>
      <StyledTeamProfile
        id={name
          .replace(/[,]/g, '')
          .replace(/[ ]/g, '-')
          .toLowerCase()}
        even={even}
      >
        <StyledTeamProfileBlockOne>
          <StyledTeamProfileImageWrap>
            <FixedImg image={image} />
          </StyledTeamProfileImageWrap>
          <StyledTeamProfileValues>
            <StyledTeamProfileValuesText>{values}</StyledTeamProfileValuesText>
          </StyledTeamProfileValues>
        </StyledTeamProfileBlockOne>
        <StyledTeamProfileBlockTwo>
          <StyledTeamProfileHeading>{name}</StyledTeamProfileHeading>
          <StyledTeamProfileText backgroundcolour={true}>
            {role}
          </StyledTeamProfileText>
          <StyledTeamProfileText>{biography}</StyledTeamProfileText>
          <StyledTeamProfileText highlighted={true}>
            "{quote}"
          </StyledTeamProfileText>
        </StyledTeamProfileBlockTwo>
      </StyledTeamProfile>
    </Animation>
  );
};

export default TeamProfile;
