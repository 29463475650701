import React, { useState } from 'react';
import styled from 'styled-components';
import {
  breakpointQuery,
  standardColours,
  brandColours,
  fontWeights,
  fontSize,
  visuallyHidden,
} from '../utilities';
import Animation from './Animation';
import Container from './Container';
import Heading from './Heading';
import { commonFormStyles, ButtonInput } from './FormFields';
import { getAnchorId } from '../utils/AnchorId';

const StyledDownloadForm = styled.section`
  position: relative;
  background-color: ${brandColours.primary};
  padding: 40px 0;

  ${breakpointQuery.tiny`
    padding-top: 50px;
    padding-bottom: 50px;
  `}

  ${breakpointQuery.small`
    padding-top: 60px;
    padding-bottom: 60px;
  `}

  ${breakpointQuery.medium`
    padding-top: 70px;
    padding-bottom: 70px;
  `}

  ${breakpointQuery.large`
    padding-top: 80px;
    padding-bottom: 80px;
  `}

  ${breakpointQuery.xxlarge`
    padding-top: 90px;
    padding-bottom: 90px;
  `}

  ${breakpointQuery.xxxxlarge`
    padding-top: 100px;
    padding-bottom: 100px;
  `}
`;

const StyledDownloadFormHeader = styled.header`
  margin-bottom: 20px;
  text-align: center;

  ${breakpointQuery.medium`
    margin-bottom: 25px;
  `}

  ${breakpointQuery.large`
    margin-bottom: 30px;
  `}
`;

const StyledDownloadFormHeading = styled(Heading)`
  color: ${standardColours.white};
`;

const StyledDownloadFormText = styled.p`
  margin-top: 10px;
  color: ${standardColours.white};
`;

const StyledDownloadFormInner = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;

  ${breakpointQuery.small`
    grid-template-columns: ${({ emailContact }) =>
      emailContact ? 'repeat(3, 1fr)' : 'repeat(2, 1fr)'};
  `}

  ${breakpointQuery.medium`
    grid-gap: 18px;
  `}
`;

const StyledDownloadFormLabel = styled.label`
  ${({ hide }) => {
    if (hide) {
      return `
        ${visuallyHidden()}
      `;
    }
  }}
`;

const StyledDownloadFormLabelText = styled.span`
  margin: 8px;
  color: ${standardColours.white};
  ${fontSize(12)};

  ${({ hide }) => {
    if (hide) {
      return `
        ${visuallyHidden()}
      `;
    }
  }}

  a {
    color: ${brandColours.tertiary};
  }
`;

const StyledDownloadFormInput = styled.input`
  ${commonFormStyles()}
`;

const StyledDownloadFormInputButton = styled(ButtonInput)`
  text-transform: uppercase;

  ${breakpointQuery.small`
    grid-column: span 2;
  `}
`;

const StyledDownloadFormFileWrapper = styled.div`
  padding: 30px;
  text-align: center;
  border: 1px solid ${brandColours.tertiary};
`;

const StyledDownloadFormFileText = styled.p`
  color: ${standardColours.white};
`;

const StyledDownloadFormFileLink = styled.a`
  display: inline-block;
  margin-top: 10px;
  color: ${standardColours.white};
  font-weight: ${fontWeights.medium};
  ${fontSize(18)}
  border-bottom: 1px solid ${standardColours.white};
`;

const DownloadForm = ({
  heading,
  text,
  file: { title, basename, url },
  emailContact,
}) => {
  const [showDownload, setShowDownload] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    const myForm = document.getElementById('download-form');
    const formData = new FormData(myForm);

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => setShowDownload(true))
      .catch(error => alert(error));
  };

  return (
    <StyledDownloadForm id={getAnchorId(heading)}>
      <Animation>
        <Container narrow={true}>
          <StyledDownloadFormHeader>
            <StyledDownloadFormHeading>{heading}</StyledDownloadFormHeading>
            {text && <StyledDownloadFormText>{text}</StyledDownloadFormText>}
          </StyledDownloadFormHeader>
          {!showDownload ? (
            <StyledDownloadFormInner
              id="download-form"
              name="download"
              method="POST"
              data-netlify="true"
              netlify-honeypot="bot-field"
              emailContact={emailContact}
              onSubmit={e => handleSubmit(e)}
            >
              <input type="hidden" name="form-name" value="download" />
              <input type="hidden" name="subject" value={heading} />
              <StyledDownloadFormLabel>
                <StyledDownloadFormLabelText hide="true">
                  Name
                </StyledDownloadFormLabelText>
                <StyledDownloadFormInput
                  type="text"
                  name="name"
                  placeholder="Name"
                  required
                />
              </StyledDownloadFormLabel>
              <StyledDownloadFormLabel>
                <StyledDownloadFormLabelText hide="true">
                  Company Name
                </StyledDownloadFormLabelText>
                <StyledDownloadFormInput
                  type="text"
                  name="company-name"
                  placeholder="Company Name"
                  required
                />
              </StyledDownloadFormLabel>
              {emailContact && (
                <>
                  <StyledDownloadFormLabel>
                    <StyledDownloadFormLabelText hide="true">
                      Email
                    </StyledDownloadFormLabelText>
                    <StyledDownloadFormInput
                      type="email"
                      name="email"
                      placeholder="Email"
                      required
                    />
                  </StyledDownloadFormLabel>
                  <StyledDownloadFormLabel>
                    <input
                      type="checkbox"
                      name="marketing-consent"
                      value="true"
                      required
                    />
                    <StyledDownloadFormLabelText>
                      I consent to Barcanet using my contact details to send me
                      this free pdf and other marketing activities.
                    </StyledDownloadFormLabelText>
                  </StyledDownloadFormLabel>
                </>
              )}

              <StyledDownloadFormLabel hide="true">
                <StyledDownloadFormLabelText>
                  Don't fill this out if you're human
                </StyledDownloadFormLabelText>
                <input name="bot-field" />
              </StyledDownloadFormLabel>
              <StyledDownloadFormInputButton type="submit" value="Submit" />
            </StyledDownloadFormInner>
          ) : (
            <StyledDownloadFormFileWrapper>
              {title && (
                <StyledDownloadFormFileText>
                  {emailContact
                    ? `Thank you for your details - we will email the '${title}' to you shortly.`
                    : `Thanks, you can download '${title}' using the link below.`}
                </StyledDownloadFormFileText>
              )}
              {!emailContact && (
                <StyledDownloadFormFileLink
                  id={basename}
                  href={url}
                  target="_blank"
                  rel="noopener"
                  download
                >
                  Download File
                </StyledDownloadFormFileLink>
              )}
            </StyledDownloadFormFileWrapper>
          )}
        </Container>
      </Animation>
    </StyledDownloadForm>
  );
};

export default DownloadForm;
