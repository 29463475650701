import React from 'react';
import styled from 'styled-components';
import { breakpointQuery } from '../utilities';
import Container from './Container';
import { PopupContactButton } from './PopupContact';

const StyledPopupContactCallToAction = styled.section`
  margin: 30px 0;
  text-align: center;

  ${breakpointQuery.small`
    margin-top: 40px;
    margin-bottom: 40px;
  `}

  ${breakpointQuery.large`
    margin-top: 50px;
    margin-bottom: 50px;
  `}

  ${breakpointQuery.xxxxlarge`
    margin-top: 60px;
    margin-bottom: 60px;
  `}
`;

const PopupContactCallToAction = ({ buttonText }) => (
  <StyledPopupContactCallToAction>
    <Container>
      <PopupContactButton buttonText={buttonText} />
    </Container>
  </StyledPopupContactCallToAction>
);

export default PopupContactCallToAction;
