import React, { Component } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import Img from 'gatsby-image';
import styled from 'styled-components';
import {
  breakpointQuery,
  sectionMargins,
  standardColours,
  brandColours,
  brandFonts,
  fontSize,
  visuallyHidden,
} from '../utilities';
import Animation from './Animation';
import Container from './Container';

const StyledTestimonialsSlider = styled.section`
  ${sectionMargins()};
`;

const StyledTestimonialsSliderInner = styled.div`
  margin: 0 auto;
  max-width: 640px;
  text-align: center;

  &:before {
    content: '“”';
    display: block;
    margin-bottom: 20px;
    height: 32px;
    color: ${brandColours.tertiary};
    font-family: ${brandFonts.secondary};
    ${fontSize(60)}
    line-height: 1;
    letter-spacing: 8px;

    ${breakpointQuery.small`
      margin-bottom: 30px;
      height: 44px;
      ${fontSize(80)}
      letter-spacing: 12px;
    `}

    ${breakpointQuery.large`
      margin-bottom: 40px;
      height: 56px;
      ${fontSize(100)}
      letter-spacing: 16px;
    `}
  }
`;

const StyledTestimonialsSliderHeading = styled.h2`
  ${visuallyHidden()}
`;

const StyledTestimonialsSliderSlider = styled(Slider)``;

const StyledTestimonialsSliderSliderItem = styled.blockquote`
  margin: 0;
`;

const StyledTestimonialsSliderQuote = styled.p`
  line-height: 1.7;

  ${breakpointQuery.small`
    ${fontSize(18)}
  `}

  ${breakpointQuery.large`
    ${fontSize(20)}
  `}
`;

const StyledTestimonialsSliderAuthor = styled.footer`
  margin-top: 20px;
  ${fontSize(13)}

  ${breakpointQuery.small`
    margin-top: 30px;
    ${fontSize(14)}
  `}

  ${breakpointQuery.large`
    margin-top: 40px;
    ${fontSize(15)}
  `}

  &:before {
    content: '';
    display: block;
    margin: 0 auto 20px;
    height: 4px;
    width: 100px;
    background-color: ${standardColours.lightGrey};

    ${breakpointQuery.small`
      margin-bottom: 30px;
    `}

    ${breakpointQuery.large`
      margin-bottom: 40px;
    `}
  }
`;

const StyledTestimonialsSliderNav = styled(Slider)`
  margin-top: 20px;

  ${breakpointQuery.small`
    margin-top: 30px;
  `}

  ${breakpointQuery.large`
    margin-top: 40px;
  `}

  .slick-slide {
    opacity: 0.4;

    &.slick-current {
      opacity: 1;
    }
  }
`;

const StyledTestimonialsSliderNavItem = styled.div`
  width: 70px;
`;

const StyledTestimonialsSliderImage = styled(Img)`
  border-radius: 50%;
`;

class TestimonialsSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  render() {
    const { heading, items } = this.props;

    return (
      <StyledTestimonialsSlider>
        <Animation>
          <Container>
            <StyledTestimonialsSliderInner>
              <StyledTestimonialsSliderHeading>
                {heading}
              </StyledTestimonialsSliderHeading>
              <StyledTestimonialsSliderSlider
                asNavFor={this.state.nav2}
                ref={slider => (this.slider1 = slider)}
                arrows={false}
              >
                {items.map(({ quote, author }, i) => (
                  <StyledTestimonialsSliderSliderItem
                    key={`testimonial-slider-item-${i}`}
                  >
                    <StyledTestimonialsSliderQuote>
                      {quote}
                    </StyledTestimonialsSliderQuote>
                    <StyledTestimonialsSliderAuthor>
                      {author}
                    </StyledTestimonialsSliderAuthor>
                  </StyledTestimonialsSliderSliderItem>
                ))}
              </StyledTestimonialsSliderSlider>
              <StyledTestimonialsSliderNav
                asNavFor={this.state.nav1}
                ref={slider => (this.slider2 = slider)}
                slidesToShow={items.length}
                swipeToSlide={true}
                focusOnSelect={true}
              >
                {items.map(({ image: { fixed, alt } }, i) => (
                  <StyledTestimonialsSliderNavItem
                    key={`testimonial-nav-item-${i}`}
                  >
                    <StyledTestimonialsSliderImage fixed={fixed} alt={alt} />
                  </StyledTestimonialsSliderNavItem>
                ))}
              </StyledTestimonialsSliderNav>
            </StyledTestimonialsSliderInner>
          </Container>
        </Animation>
      </StyledTestimonialsSlider>
    );
  }
}

export default TestimonialsSlider;
