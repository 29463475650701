import React from 'react';
import styled from 'styled-components';
import {
  breakpointQuery,
  standardColours,
  brandColours,
  fontWeights,
  fontSize,
  sectionMargins,
} from '../utilities';
import Animation from './Animation';
import SectionHeader from './SectionHeader';
import Container from './Container';
import { PopupContactButton } from './PopupContact';
import { getAnchorId } from '../utils/AnchorId';

const StyledVideos = styled.section`
  ${sectionMargins()};
`;

const StyledVideosHeader = styled(SectionHeader)`
  margin-bottom: 30px;

  ${breakpointQuery.small`
    margin-bottom: 40px;
  `}

  ${breakpointQuery.large`
    margin-bottom: 50px;
  `}

  ${breakpointQuery.xxlarge`
    margin-bottom: 60px;
  `}
`;

const StyledVideosInner = styled.div`
  ${breakpointQuery.medium`
    display: flex;
  `}
`;

const StyledVideosList = styled.ul`
  ${breakpointQuery.medium`
    width: 35%;
  `}

  ${breakpointQuery.large`
    width: 30%;
  `}
`;

const StyledVideosItem = styled.li`
  margin-top: 10px;
  padding: 16px 22px;
  background-color: ${standardColours.lighterGrey};
  border-radius: 10px;
  cursor: pointer;

  &:first-child {
    margin-top: 0;
  }

  &:hover {
    color: ${standardColours.white};
    background-color: ${brandColours.tertiary};
  }
`;

const StyledVideosHeading = styled.h3`
  font-weight: ${fontWeights.medium};
  ${fontSize(18)}

  ${breakpointQuery.large`
    ${fontSize(20)}
  `}
`;

const StyledVideosText = styled.p`
  margin-top: 8px;
`;

const StyledVideosVideoWrap = styled.div`
  position: relative;
  margin-top: 20px;
  border: 10px solid ${standardColours.lighterGrey};
  border-top-width: 26px;
  border-bottom-width: 18px;
  border-radius: 10px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: -14px;
    display: block;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    opacity: 0.5;
  }

  &:before {
    left: 2px;
    background-color: red;
  }

  &:after {
    left: 14px;
    background-color: green;
  }

  ${breakpointQuery.medium`
    margin-top: 0;
    margin-left: 20px;
    width: 65%;
  `}

  ${breakpointQuery.large`
    width: 70%;
  `}
`;

const StyledVideosVideo = styled.video`
  display: block;
  height: 100%;
  width: 100%;
  object-position: top;
  background-color: ${standardColours.lighterGrey};
`;

const StyledVideosFooter = styled.footer`
  margin-top: 25px;
  text-align: center;

  ${breakpointQuery.small`
    margin-top: 30px;
  `}

  ${breakpointQuery.large`
    margin-top: 35px;
  `}

  ${breakpointQuery.xxlarge`
    margin-top: 40px;
  `}
`;

const switchVideoUrl = url => {
  const videoPlayer = document.getElementById('video-player');
  videoPlayer.getElementsByTagName('source')[0].src = url;
  videoPlayer.load();
  videoPlayer.play();
};

const Videos = ({ id, heading, text, videos, buttonText, isCampaignPage }) => (
  <StyledVideos id={getAnchorId(heading)}>
    <Animation>
      <StyledVideosHeader heading={heading} text={text} />
      <Container>
        <StyledVideosInner>
          <StyledVideosList>
            {videos.map(({ url, title, alt }, i) => (
              <StyledVideosItem
                key={`${id}-${i}`}
                onClick={() => switchVideoUrl(url)}
              >
                <StyledVideosHeading>{title}</StyledVideosHeading>
                <StyledVideosText>{alt}</StyledVideosText>
              </StyledVideosItem>
            ))}
          </StyledVideosList>
          <StyledVideosVideoWrap>
            <StyledVideosVideo id="video-player" autoPlay muted controls>
              <source src={videos[0].url} type="video/mp4" />
            </StyledVideosVideo>
          </StyledVideosVideoWrap>
        </StyledVideosInner>
        {isCampaignPage && (
          <StyledVideosFooter>
            <PopupContactButton buttonText={buttonText} />
          </StyledVideosFooter>
        )}
      </Container>
    </Animation>
  </StyledVideos>
);

export default Videos;
