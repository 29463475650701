import React from 'react';
import { graphql } from 'gatsby';
import BestModuleCalculator from './BestModuleCalculator';
import Cards from './Cards';
import ContactCallToAction from './ContactCallToAction';
import Content from './Content';
import DemoCallToAction from './DemoCallToAction';
import DemoForm from './DemoForm';
import Divider from './Divider';
import DownloadForm from './DownloadForm';
import FeaturedLogos from './FeaturedLogos';
import FeaturedSteps from './FeaturedSteps';
import FullWidthImage from './FullWidthImage';
import GartnerContent from './GartnerContent';
import IconCallToActions from './IconCallToActions';
import Image from './Image';
import ImageVideoContent from './ImageVideoContent';
import MiniCaseStudies from './MiniCaseStudies';
import PopupContactCallToAction from './PopupContactCallToAction';
import PricingPlans from './PricingPlans';
import ProductsServicesList from './ProductsServicesList';
import Statistics from './Statistics';
import TeamProfiles from './TeamProfiles';
import Testimonial from './Testimonial';
import Testimonials from './Testimonials';
import TestimonialsSlider from './TestimonialsSlider';
import Video from './Video';
import Videos from './Videos';

const ModularBlocks = ({ items, isCampaignPage, buttonText }) => (
  <div id="modular-blocks">
    {items.map(item => (
      <React.Fragment key={item.id}>
        {item.model.apiKey === 'best_module_calculator_modular_block' && (
          <BestModuleCalculator heading={item.heading} text={item.text} />
        )}
        {item.model.apiKey === 'case_studies_modular_block' && (
          <Cards
            heading={item.heading}
            text={item.text}
            linkText="View more"
            linkUrl="/resources/case-studies/"
            items={item.caseStudies}
            baseSlug="/resources/case-studies/"
          />
        )}
        {item.model.apiKey === 'contact_call_to_action_modular_block' && (
          <ContactCallToAction
            heading={item.heading}
            content={item.content}
            linkText={item.linkText}
            contactType={item.contactType}
            externalUrl={item.externalUrl}
          />
        )}
        {item.model.apiKey === 'content_modular_block' && (
          <Content content={item.content} links={item.links} />
        )}
        {item.model.apiKey === 'demo_call_to_action_modular_block' && (
          <DemoCallToAction />
        )}
        {item.model.apiKey === 'demo_form_modular_block' && (
          <DemoForm heading={item.heading} text={item.text} />
        )}
        {item.model.apiKey === 'dots_divider_modular_block' && <Divider />}
        {item.model.apiKey === 'download_form_modular_block' && (
          <DownloadForm
            heading={item.heading}
            text={item.text}
            file={item.file}
            emailContact={item.emailContact}
          />
        )}
        {item.model.apiKey === 'featured_logos_modular_block' && (
          <FeaturedLogos items={item.logos} />
        )}
        {item.model.apiKey === 'featured_steps_modular_block' && (
          <FeaturedSteps
            heading={item.heading}
            items={item.featuredSteps}
            text={item.text}
            isCampaignPage={isCampaignPage}
            navAnchorLinkText={item.navAnchorLinkText}
            buttonText={buttonText}
          />
        )}
        {item.model.apiKey === 'full_width_image_modular_block' && (
          <FullWidthImage image={item.image} />
        )}
        {item.model.apiKey === 'gartner_content_modular_block' && (
          <GartnerContent text={item.text} />
        )}
        {item.model.apiKey === 'icon_call_to_actions_modular_block' && (
          <IconCallToActions
            heading={item.instance.heading}
            text={item.instance.text}
            items={item.instance.iconCallToActions}
          />
        )}
        {item.model.apiKey === 'image_large_modular_block' && (
          <Image
            heading={item.heading}
            image={item.image}
            text={item.text}
            isLarge={true}
            navAnchorLinkText={item.navAnchorLinkText}
          />
        )}
        {item.model.apiKey === 'image_content_modular_block' && (
          <ImageVideoContent
            heading={item.heading}
            content={item.content}
            image={item.image}
            flip={item.flip}
            highlight={item.highlight}
          />
        )}
        {item.model.apiKey === 'image_modular_block' && (
          <Image heading={item.heading} image={item.image} />
        )}
        {item.model.apiKey === 'mini_case_studies_modular_block' && (
          <MiniCaseStudies
            heading={item.heading}
            items={item.miniCaseStudies}
          />
        )}
        {item.model.apiKey === 'molecules_divider_modular_block' && (
          <Divider alt="true" />
        )}
        {item.model.apiKey === 'news_insights_modular_block' && (
          <Cards
            heading={item.heading}
            text={item.text}
            linkText="View more"
            linkUrl="/resources/insights/"
            items={item.newsInsights}
            baseSlug="/resources/insights/"
          />
        )}
        {item.model.apiKey === 'popup_contact_call_to_action_modular_block' && (
          <PopupContactCallToAction buttonText={buttonText} />
        )}
        {item.model.apiKey === 'pricing_plans_modular_block' && (
          <PricingPlans
            heading={item.heading}
            items={item.pricingPlans}
            text={item.text}
            navAnchorLinkText={item.navAnchorLinkText}
            buttonText={buttonText}
          />
        )}
        {item.model.apiKey === 'products_services_modular_block' && (
          <ProductsServicesList
            heading={item.heading}
            text={item.text}
            items={item.productsServices}
          />
        )}
        {item.model.apiKey === 'statistics_modular_block' && (
          <Statistics
            heading={item.instance.heading}
            text={item.instance.text}
            items={item.instance.statistics}
          />
        )}
        {item.model.apiKey === 'team_profiles_modular_block' && (
          <TeamProfiles
            heading={item.instance.heading}
            text={item.instance.text}
            items={item.instance.teamProfiles}
          />
        )}
        {item.model.apiKey === 'testimonial_modular_block' && (
          <Testimonial quote={item.quote} author={item.author} />
        )}
        {item.model.apiKey === 'testimonials_modular_block' && (
          <Testimonials
            heading={item.heading}
            items={item.testimonials}
            isCampaignPage={isCampaignPage}
            navAnchorLinkText={item.navAnchorLinkText}
            buttonText={buttonText}
          />
        )}
        {item.model.apiKey === 'testimonials_slider_modular_block' && (
          <TestimonialsSlider
            heading="Testimonials"
            items={item.instance.testimonials}
          />
        )}
        {item.model.apiKey === 'video_content_modular_block' && (
          <ImageVideoContent
            heading={item.heading}
            content={item.content}
            video={item.video}
            flip={item.flip}
            highlight={item.highlight}
          />
        )}
        {item.model.apiKey === 'video_modular_block' && (
          <Video video={item.video} />
        )}
        {item.model.apiKey === 'videos_modular_block' && (
          <Videos
            id={item.id}
            heading={item.heading}
            text={item.text}
            videos={item.videos}
            isCampaignPage={isCampaignPage}
            navAnchorLinkText={item.navAnchorLinkText}
            buttonText={buttonText}
          />
        )}
      </React.Fragment>
    ))}
  </div>
);

export default ModularBlocks;

export const ModularBlockFragments = graphql`
  fragment BestModuleCalculatorModularBlockFragment on DatoCmsBestModuleCalculatorModularBlock {
    id
    model {
      apiKey
    }
    heading
    text
  }

  fragment CaseStudiesModularBlockFragment on DatoCmsCaseStudiesModularBlock {
    id
    model {
      apiKey
    }
    heading
    text
    caseStudies {
      featuredImage {
        fluid(
          maxHeight: 225
          maxWidth: 400
          imgixParams: { auto: "compress", fit: "crop", h: "225", w: "400" }
        ) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
      title
      slug
    }
  }

  fragment ContactCallToActionModularBlockFragment on DatoCmsContactCallToActionModularBlock {
    id
    model {
      apiKey
    }
    heading
    content
    linkText
    contactType
    externalUrl
  }

  fragment ContentModularBlockFragment on DatoCmsContentModularBlock {
    id
    model {
      apiKey
    }
    content
    links {
      text
      url
    }
  }

  fragment DemoCallToActionModularBlockFragment on DatoCmsDemoCallToActionModularBlock {
    id
    model {
      apiKey
    }
  }

  fragment DemoFormModularBlockFragment on DatoCmsDemoFormModularBlock {
    id
    model {
      apiKey
    }
    heading
    text
  }

  fragment DotsDividerModularBlockFragment on DatoCmsDotsDividerModularBlock {
    id
    model {
      apiKey
    }
  }

  fragment DownloadFormModularBlockFragment on DatoCmsDownloadFormModularBlock {
    id
    model {
      apiKey
    }
    heading
    text
    file {
      title
      basename
      url
    }
    emailContact
  }

  fragment FeaturedLogosModularBlockFragment on DatoCmsFeaturedLogosModularBlock {
    id
    model {
      apiKey
    }
    logos {
      alt
      url
    }
  }

  fragment FeaturedStepsModularBlockFragment on DatoCmsFeaturedStepsModularBlock {
    id
    model {
      apiKey
    }
    heading
    featuredSteps {
      text
      textBackgroundColour {
        hex
      }
      image {
        url
        alt
      }
    }
    text
    navAnchorLinkText
  }

  fragment FullWidthImageModularBlockFragment on DatoCmsFullWidthImageModularBlock {
    id
    model {
      apiKey
    }
    image {
      fixed(
        width: 1600
        imgixParams: { auto: "compress", fit: "crop", w: "1600" }
      ) {
        ...GatsbyDatoCmsFixed
      }
      alt
    }
  }

  fragment GartnerContentModularBlockFragment on DatoCmsGartnerContentModularBlock {
    id
    model {
      apiKey
    }
    text
  }

  fragment IconCallToActionsModularBlockFragment on DatoCmsIconCallToActionsModularBlock {
    id
    model {
      apiKey
    }
    instance {
      heading
      text
      iconCallToActions {
        id
        icon {
          url
          alt
        }
        heading
        text
        fileDownload {
          basename
          url
        }
      }
    }
  }

  fragment ImageLargeModularBlockFragment on DatoCmsImageLargeModularBlock {
    id
    model {
      apiKey
    }
    heading
    image {
      fixed(
        width: 1200
        imgixParams: { auto: "compress", fit: "crop", w: "1200" }
      ) {
        ...GatsbyDatoCmsFixed
      }
      alt
    }
    text
    navAnchorLinkText
  }

  fragment ImageContentModularBlockFragment on DatoCmsImageContentModularBlock {
    id
    model {
      apiKey
    }
    heading
    content
    image {
      fixed(
        height: 620
        width: 600
        imgixParams: { auto: "compress", fit: "crop", h: "620", w: "600" }
      ) {
        ...GatsbyDatoCmsFixed
      }
      alt
    }
    flip
    highlight
  }

  fragment ImageModularBlockFragment on DatoCmsImageModularBlock {
    id
    model {
      apiKey
    }
    heading
    image {
      fixed(
        width: 800
        imgixParams: { auto: "compress", fit: "crop", w: "800" }
      ) {
        ...GatsbyDatoCmsFixed
      }
      alt
    }
  }

  fragment MiniCaseStudiesModularBlockFragment on DatoCmsMiniCaseStudiesModularBlock {
    id
    model {
      apiKey
    }
    heading
    miniCaseStudies {
      heading
      logo {
        url
        alt
      }
      challenge
      solution
      results
    }
  }

  fragment MoleculesDividerModularBlockFragment on DatoCmsMoleculesDividerModularBlock {
    id
    model {
      apiKey
    }
  }

  fragment NewsInsightsModularBlockFragment on DatoCmsNewsInsightsModularBlock {
    id
    model {
      apiKey
    }
    heading
    text
    newsInsights {
      featuredImage {
        fluid(
          maxHeight: 225
          maxWidth: 400
          imgixParams: { auto: "compress", fit: "crop", h: "225", w: "400" }
        ) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
      title
      slug
    }
  }

  fragment PopupContactCallToActionModularBlockFragment on DatoCmsPopupContactCallToActionModularBlock {
    id
    model {
      apiKey
    }
  }

  fragment PricingPlansModularBlockFragment on DatoCmsPricingPlansModularBlock {
    id
    model {
      apiKey
    }
    heading
    pricingPlans {
      name
      summary
      price
      fromPrice
      users
      planFeatures {
        name
        included
      }
    }
    text
    navAnchorLinkText
  }

  fragment ProductsServicesModularBlockFragment on DatoCmsProductsServicesModularBlock {
    id
    model {
      apiKey
    }
    heading
    text
    productsServices {
      id
      image {
        url
        alt
      }
      title
      introductionText
      slug
    }
  }

  fragment StatisticsModularBlockFragment on DatoCmsStatisticsModularBlock {
    id
    model {
      apiKey
    }
    instance {
      heading
      text
      statistics {
        id
        number
        description
      }
    }
  }

  fragment TeamProfilesModularBlockFragment on DatoCmsTeamProfilesModularBlock {
    id
    model {
      apiKey
    }
    instance {
      heading
      text
      teamProfiles {
        id
        image {
          fixed(
            height: 460
            width: 460
            imgixParams: { auto: "compress", fit: "crop", h: "460", w: "460" }
          ) {
            ...GatsbyDatoCmsFixed
          }
          alt
        }
        values
        name
        role
        biography
        quote
      }
    }
  }

  fragment TestimonialModularBlockFragment on DatoCmsTestimonialModularBlock {
    id
    model {
      apiKey
    }
    quote
    author
  }

  fragment TestimonialsModularBlockFragment on DatoCmsTestimonialsModularBlock {
    id
    model {
      apiKey
    }
    heading
    testimonials {
      quote
      author
      bio
      image {
        fixed(
          height: 46
          width: 46
          imgixParams: { auto: "compress", fit: "crop" }
        ) {
          ...GatsbyDatoCmsFixed
        }
        alt
      }
    }
    navAnchorLinkText
  }

  fragment TestimonialsSliderModularBlockFragment on DatoCmsTestimonialsSliderModularBlock {
    id
    model {
      apiKey
    }
    instance {
      testimonials {
        quote
        author
        image {
          fixed(
            height: 70
            width: 70
            imgixParams: { auto: "compress", fit: "crop" }
          ) {
            ...GatsbyDatoCmsFixed
          }
          alt
        }
      }
    }
  }

  fragment VideoContentModularBlockFragment on DatoCmsVideoContentModularBlock {
    id
    model {
      apiKey
    }
    heading
    content
    video {
      url
      basename
    }
    flip
    highlight
  }

  fragment VideoModularBlockFragment on DatoCmsVideoModularBlock {
    id
    model {
      apiKey
    }
    video {
      url
      title
      basename
    }
  }

  fragment VideosModularBlockFragment on DatoCmsVideosModularBlock {
    id
    model {
      apiKey
    }
    heading
    text
    videos {
      url
      title
      alt
    }
    navAnchorLinkText
  }
`;
