import React from 'react';
import styled from 'styled-components';
import {
  standardColours,
  brandColours,
  fontWeights,
  fontSize,
} from '../utilities';
import Animation from './Animation';

const StyledIconCallToAction = styled.article``;

const StyledIconCallToActionImage = styled.img`
  margin-bottom: 28px;
  height: 80px;
  max-width: 80px;
`;

const StyledIconCallToActionHeading = styled.h3`
  font-weight: ${fontWeights.medium};
  ${fontSize(18)}

  &:before {
    content: '';
    display: block;
    margin-bottom: 24px;
    height: 4px;
    width: 60px;
    background-color: ${brandColours.primary};
  }
`;

const StyledIconCallToActionText = styled.p`
  margin-top: 14px;
  ${fontSize(15)}
  line-height: 1.7;
`;

const StyledIconCallToActionButton = styled.a`
  margin-top: 14px;
  display: inline-block;
  padding: 10px 32px;
  color: ${standardColours.white};
  line-height: 1.4;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  background-color: ${brandColours.primary};
  border-radius: 8px;
`;

const IconCallToAction = ({
  data: {
    icon: { url, alt },
    heading,
    text,
    fileDownload,
  },
}) => {
  return (
    <StyledIconCallToAction>
      <Animation>
        <StyledIconCallToActionImage src={url} loading="lazy" alt={alt} />
        <StyledIconCallToActionHeading>{heading}</StyledIconCallToActionHeading>
        <StyledIconCallToActionText>{text}</StyledIconCallToActionText>
        {fileDownload && (
          <StyledIconCallToActionButton
            id={fileDownload.basename}
            href={fileDownload.url}
            target="_blank"
            rel="noopener"
            download
          >
            Download Free
          </StyledIconCallToActionButton>
        )}
      </Animation>
    </StyledIconCallToAction>
  );
};

export default IconCallToAction;
