import React from 'react';
import styled from 'styled-components';
import {
  breakpointQuery,
  sectionMargins,
  brandColours,
  fontSize,
} from '../utilities';
import Animation from './Animation';
import Container from './Container';
import Heading from './Heading';
import HTMLContent from './HTMLContent';
import ButtonLink from './ButtonLink';

const StyledContactCallToAction = styled.section`
  ${sectionMargins()};
`;

const StyledContactCallToActionHeading = styled(Heading)`
  text-align: center;
`;

const StyledContactCallToActionButtonWrap = styled.div`
  text-align: center;
`;

const StyledContactCallToActionButton = styled(ButtonLink)`
  ${breakpointQuery.small`
    padding: 10px 34px;
    ${fontSize(17)};
  `}

  ${breakpointQuery.large`
    padding: 10px 36px;
    ${fontSize(18)};
  `}
`;

const ContactCallToAction = ({
  heading,
  content,
  linkText,
  contactType,
  externalUrl,
}) => {
  return (
    <StyledContactCallToAction>
      <Animation>
        <Container narrow={true}>
          <StyledContactCallToActionHeading>
            {heading}
          </StyledContactCallToActionHeading>
          <HTMLContent content={content} />
          <StyledContactCallToActionButtonWrap>
            <StyledContactCallToActionButton
              {...(externalUrl
                ? {
                    href: externalUrl,
                    target: '_blank',
                  }
                : {
                    to: '/contact/',
                    state: { contactType: contactType },
                  })}
              backgroundcolour={brandColours.quaternary}
            >
              {linkText}
            </StyledContactCallToActionButton>
          </StyledContactCallToActionButtonWrap>
        </Container>
      </Animation>
    </StyledContactCallToAction>
  );
};

export default ContactCallToAction;
