import React from 'react';
import styled from 'styled-components';
import { sectionMargins, standardColours } from '../utilities';
import Animation from './Animation';
import Container from './Container';

const StyledVideo = styled.section`
  ${sectionMargins()};
`;

const StyledVideoPlayerWrap = styled.div`
  position: relative;
  margin-top: 20px;
  border: 10px solid ${standardColours.lighterGrey};
  border-top-width: 26px;
  border-bottom-width: 18px;
  border-radius: 10px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: -14px;
    display: block;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    opacity: 0.5;
  }

  &:before {
    left: 2px;
    background-color: red;
  }

  &:after {
    left: 14px;
    background-color: green;
  }
`;

const StyledVideoPlayer = styled.video`
  display: block;
  width: 100%;
`;

const StyledVideoText = styled.p`
  margin-top: 16px;
`;

const Video = ({ video: { url, title, basename } }) => {
  return (
    <StyledVideo id={basename}>
      <Animation>
        <Container narrow={true}>
          <StyledVideoPlayerWrap>
            <StyledVideoPlayer autoplay muted controls>
              <source src={url} type="video/mp4" />
            </StyledVideoPlayer>
          </StyledVideoPlayerWrap>
          <StyledVideoText>{title}</StyledVideoText>
        </Container>
      </Animation>
    </StyledVideo>
  );
};

export default Video;
