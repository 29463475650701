import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

const StyledFixedImg = styled(Img)`
  vertical-align: bottom;
  max-height: 0;
  width: 100% !important;
  max-width: 100%;

  ${({ fixed }) => {
    return `
      padding-bottom: ${100 / fixed.aspectRatio}%;
    `;
  }}
`;

const FixedImg = ({ image: { fixed, alt }, className }) => {
  return <StyledFixedImg fixed={fixed} alt={alt} className={className} />;
};

export default FixedImg;
