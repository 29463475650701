import React from 'react';
import styled from 'styled-components';
import { breakpointQuery, sectionMargins } from '../utilities';
import Animation from './Animation';
import Container from './Container';
import SectionHeader from './SectionHeader';
import IconCallToAction from './IconCallToAction';
import { getAnchorId } from '../utils/AnchorId';

const StyledIconCallToActions = styled.section`
  ${sectionMargins()};
`;

const StyledIconCallToActionsHeader = styled(SectionHeader)`
  margin-bottom: 30px;

  ${breakpointQuery.small`
    margin-bottom: 40px;
  `}

  ${breakpointQuery.large`
    margin-bottom: 50px;
  `}

  ${breakpointQuery.xxlarge`
    margin-bottom: 60px;
  `}
`;

const StyledIconCallToActionsInner = styled.div`
  display: grid;
  grid-gap: 30px;

  ${breakpointQuery.tiny`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${breakpointQuery.small`
    grid-gap: 40px;
  `}

  ${breakpointQuery.medium`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${breakpointQuery.large`
    grid-row-gap: 50px;
  `}
`;

const IconCallToActions = ({ heading, text, items }) => (
  <StyledIconCallToActions id={getAnchorId(heading)}>
    <Animation>
      <StyledIconCallToActionsHeader heading={heading} text={text} />
      <Container>
        <StyledIconCallToActionsInner>
          {items.map(item => (
            <IconCallToAction key={item.id} data={item} />
          ))}
        </StyledIconCallToActionsInner>
      </Container>
    </Animation>
  </StyledIconCallToActions>
);

export default IconCallToActions;
