import React from 'react';
import styled from 'styled-components';
import {
  breakpointQuery,
  standardColours,
  brandColours,
  sectionMargins,
} from '../utilities';
import Container from './Container';
import SectionHeader from './SectionHeader';
import TeamProfile from './TeamProfile';

const StyledTeamProfiles = styled.section`
  ${sectionMargins()};
  padding-top: 60px;
  background-color: ${standardColours.lighterGrey};

  ${breakpointQuery.tiny`
    padding-top: 70px;
  `}

  ${breakpointQuery.small`
    padding-top: 80px;
  `}

  ${breakpointQuery.medium`
    padding-top: 90px;
  `}

  ${breakpointQuery.large`
    padding-top: 100px;
  `}

  ${breakpointQuery.xxlarge`
    padding-top: 110px;
  `}

  ${breakpointQuery.xxxxlarge`
    padding-top: 120px;
  `}
`;

const StyledTeamProfilesContent = styled.div`
  margin-top: 60px;
  background-color: ${brandColours.secondary};

  ${breakpointQuery.small`
    margin-top: 80px;
  `}

  ${breakpointQuery.medium`
    margin-top: 100px;
  `}

  ${breakpointQuery.large`
    margin-top: 120px;
  `}
`;

const StyledTeamProfilesInner = styled.div`
  position: relative;
  top: -20px;

  ${breakpointQuery.small`
    top: -30px;
  `}

  ${breakpointQuery.large`
    top: -40px;
  `}
`;

const TeamProfiles = ({ heading, text, items }) => {
  return (
    <StyledTeamProfiles>
      <SectionHeader heading={heading} text={text} />
      <StyledTeamProfilesContent>
        <Container>
          <StyledTeamProfilesInner>
            {items.map((item, i) => (
              <TeamProfile key={item.id} data={item} even={i % 2} />
            ))}
          </StyledTeamProfilesInner>
        </Container>
      </StyledTeamProfilesContent>
    </StyledTeamProfiles>
  );
};

export default TeamProfiles;
