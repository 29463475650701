import React from 'react';
import styled from 'styled-components';
import {
  breakpointSizes,
  breakpointQuery,
  brandColours,
  fontWeights,
  fontSize,
  fluidFontSize,
} from '../utilities';

const StyledStatistic = styled.div`
  display: inline-block;
  padding: 10px;
  max-width: 250px;
  vertical-align: top;

  ${breakpointQuery.tiny`
    width: 50%;
  `}

  ${breakpointQuery.small`
    padding: 15px;
  `}

  ${breakpointQuery.smedium`
    width: calc(100% / 3);
  `}

  ${({ count }) => {
    if (count) {
      return breakpointQuery.mlarge`
        width: ${100 / count}%;
      `;
    }
  }}

  ${breakpointQuery.large`
    padding: 20px;
  `}
`;

const StyledStatisticNumber = styled.span`
  display: inline-block;
  color: ${brandColours.primary};
  font-weight: ${fontWeights.bold};
  ${fluidFontSize('40px', '62px', breakpointSizes.tiny, breakpointSizes.xlarge)}
`;

const StyledStatisticText = styled.p`
  margin-top: 4px;
  font-weight: ${fontWeights.medium};
  ${fontSize(15)}
  line-height: 1.7;
  white-space: pre-line;

  ${breakpointQuery.small`
    margin-top: 6px;
  `}

  ${breakpointQuery.large`
    margin-top: 8px;
  `}
`;

const Statistic = ({ data: { number, description }, count }) => {
  return (
    <StyledStatistic count={count}>
      <StyledStatisticNumber>{number}</StyledStatisticNumber>
      <StyledStatisticText>{description}</StyledStatisticText>
    </StyledStatistic>
  );
};

export default Statistic;
