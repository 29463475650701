import React from 'react';
import styled from 'styled-components';
import {
  breakpointQuery,
  brandColours,
  fontWeights,
  fontSize,
} from '../utilities';

export const StyledHTMLContent = styled.div`
  margin: 20px 0;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 12px 0;
    font-weight: ${fontWeights.medium};
    line-height: 1.5;

    ${breakpointQuery.tiny`
      margin-top: 14px;
      margin-bottom: 14px;
    `};

    ${breakpointQuery.medium`
      margin-top: 16px;
      margin-bottom: 16px;
    `};
  }

  h1 {
    ${fontSize(24)};

    ${breakpointQuery.tiny`
      ${fontSize(26)};
    `};
  }

  h2 {
    ${fontSize(22)};

    ${breakpointQuery.tiny`
      ${fontSize(24)};
    `};
  }

  h3 {
    ${fontSize(20)};

    ${breakpointQuery.tiny`
      ${fontSize(22)};
    `};
  }

  h4 {
    ${fontSize(19)};

    ${breakpointQuery.tiny`
      ${fontSize(20)};
    `};
  }

  h5 {
    ${fontSize(18)};

    ${breakpointQuery.tiny`
      ${fontSize(19)};
    `};
  }

  h6 {
    ${fontSize(17)};

    ${breakpointQuery.tiny`
      ${fontSize(18)};
    `};
  }

  p {
    margin-bottom: 20px;
    line-height: 1.7;

    ${breakpointQuery.tiny`
      margin-bottom: 24px;
    `};

    ${breakpointQuery.medium`
      margin-bottom: 28px;
    `};

    &:last-child {
      margin-bottom: 0;
    }

    a {
      color: ${brandColours.tertiary};
    }
  }

  ul,
  ol {
    margin-bottom: 20px;

    ${breakpointQuery.tiny`
      margin-bottom: 24px;
    `};

    ${breakpointQuery.medium`
      margin-bottom: 28px;
    `};

    li {
      margin-bottom: 10px;
      line-height: 1.7;

      ${breakpointQuery.tiny`
        margin-bottom: 12px;
      `};

      ${breakpointQuery.medium`
        margin-bottom: 14px;
      `};

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  ul {
    ${breakpointQuery.tiny`
      column-count: 2;
      column-gap: 20px;
    `}

    li {
      display: inline-block;
      width: 100%;
      padding-left: 26px;

      &:before {
        content: '✔';
        margin-right: 10px;
        margin-left: -24px;
        color: ${brandColours.quinary};
      }
    }
  }

  ol {
    list-style-type: decimal;

    li {
      margin-left: 16px;
    }
  }

  img {
    margin-top: 12px;
    margin-bottom: 12px;

    &[style*='float: right'] {
      margin-left: 20px;
    }

    &[style*='float: left'] {
      margin-right: 20px;
    }
  }
`;

const HTMLContent = ({ content }) => {
  return <StyledHTMLContent dangerouslySetInnerHTML={{ __html: content }} />;
};

export default HTMLContent;
