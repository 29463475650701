import React from 'react';
import styled from 'styled-components';
import {
  breakpointQuery,
  sectionPaddings,
  standardColours,
  brandColours,
  fontWeights,
  fontSize,
} from '../utilities';
import Animation from './Animation';
import SectionHeader from './SectionHeader';
import Container from './Container';
import { PopupContactButton } from './PopupContact';
import { getAnchorId } from '../utils/AnchorId';
import checkmark from '../images/checkmark.svg';
import disabledCheckmark from '../images/x-mark.svg';

const StyledPricingPlans = styled.section`
  ${sectionPaddings()};
  background-color: ${brandColours.senary};
`;

const StyledPricingPlansHeader = styled(SectionHeader)`
  margin-bottom: 30px;

  ${breakpointQuery.small`
    margin-bottom: 40px;
  `}

  ${breakpointQuery.large`
    margin-bottom: 50px;
  `}

  ${breakpointQuery.xxlarge`
    margin-bottom: 60px;
  `}
`;

const StyledPricingPlansItems = styled.div`
  display: grid;
  gap: 20px;

  ${breakpointQuery.medium`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${breakpointQuery.large`
    gap: 30px;
  `}
`;

const StyledPricingPlansItem = styled.article`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 20px;
  padding: 30px 30px 40px;
  background-color: ${standardColours.white};
  border-radius: 20px;
`;

const StyledPricingPlansName = styled.h3`
  ${fontSize(20)};
  font-weight: ${fontWeights.medium};

  &:after {
    content: '';
    width: 60px;
    height: 6px;
    display: block;
    background-color: ${brandColours.primary};
    margin-top: 16px;
  }
`;

const StyledPricingPlansSummary = styled.p`
  ${fontSize(14)};
  line-height: 1.9;
`;

const StyledPricingPlansPrice = styled.p`
  display: grid;
  position: relative;
  gap: 6px;
`;

const StyledPricingPlansPriceFrom = styled.span`
  position: absolute;
  top: -12px;
  left: 0;
  ${fontSize(14)};
`;

const StyledPricingPlansPriceAmount = styled.strong`
  font-weight: ${fontWeights.medium};
  ${fontSize(40)};
`;

const StyledPricingPlansPriceInfo = styled.span`
  ${fontSize(14)};
`;

const StyledPricingPlansUsers = styled.p`
  font-weight: ${fontWeights.medium};
  ${fontSize(20)};
  display: flex;
  align-items: center;
  gap: 16px;
`;

const StyledPricingPlansUsersText = styled.span`
  ${fontSize(30)};
`;

const StyledPricingPlansFeatures = styled.ol`
  display: grid;
  row-gap: 16px;
  column-gap: 32px;

  ${breakpointQuery.small`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${breakpointQuery.medium`
    grid-template-columns: 1fr;
  `}
`;

const StyledPricingPlansFeature = styled.li`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const StyledPricingPlansButton = styled(PopupContactButton)`
  margin: 30px auto 0;
  width: 100%;
  max-width: 320px;
`;

const StyledPricingPlansText = styled.p`
  margin-top: 16px;
  ${fontSize(14)};
  line-height: 1.7;
  text-align: center;

  ${breakpointQuery.small`
    margin-top: 18px;
    ${fontSize(15)};
  `}

  ${breakpointQuery.large`
    margin-top: 20px;
    ${fontSize(16)};
  `}
`;

const PricingPlans = ({ heading, items, text, buttonText }) => (
  <StyledPricingPlans id={getAnchorId(heading)}>
    <Animation>
      <StyledPricingPlansHeader heading={heading} />
      <Container>
        <StyledPricingPlansItems>
          {items.map(
            ({ name, summary, price, fromPrice, users, planFeatures }, id) => (
              <StyledPricingPlansItem key={id}>
                <StyledPricingPlansName>{name}</StyledPricingPlansName>
                <StyledPricingPlansSummary>{summary}</StyledPricingPlansSummary>
                <StyledPricingPlansPrice>
                  {fromPrice && (
                    <StyledPricingPlansPriceFrom>
                      From
                    </StyledPricingPlansPriceFrom>
                  )}
                  <StyledPricingPlansPriceAmount>{`£${price}`}</StyledPricingPlansPriceAmount>
                  <StyledPricingPlansPriceInfo>
                    Annual Fee
                  </StyledPricingPlansPriceInfo>
                </StyledPricingPlansPrice>
                <StyledPricingPlansUsers>
                  Users
                  <StyledPricingPlansUsersText>
                    {users}
                  </StyledPricingPlansUsersText>
                </StyledPricingPlansUsers>
                <StyledPricingPlansFeatures>
                  {planFeatures.map(({ name, included }, id) => (
                    <StyledPricingPlansFeature key={id}>
                      <img
                        src={included ? checkmark : disabledCheckmark}
                        loading="lazy"
                        alt={included ? 'Included' : ' Not Included'}
                      />
                      {name}
                    </StyledPricingPlansFeature>
                  ))}
                </StyledPricingPlansFeatures>
                <StyledPricingPlansButton buttonText={buttonText} />
              </StyledPricingPlansItem>
            )
          )}
        </StyledPricingPlansItems>
        {text && <StyledPricingPlansText>{text}</StyledPricingPlansText>}
      </Container>
    </Animation>
  </StyledPricingPlans>
);

export default PricingPlans;
