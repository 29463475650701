import React from 'react';
import styled from 'styled-components';
import { breakpointQuery, standardColours } from '../utilities';
import Animation from './Animation';
import Container from './Container';
import SectionHeader from './SectionHeader';
import Statistic from './Statistic';

const StyledStatistics = styled.section`
  margin-top: 60px;

  ${breakpointQuery.tiny`
    margin-top: 70px;
  `}

  ${breakpointQuery.small`
    margin-top: 80px;
  `}

  ${breakpointQuery.medium`
    margin-top: 90px;
  `}

  ${breakpointQuery.large`
    margin-top: 100px;
  `}

  ${breakpointQuery.xxlarge`
    margin-top: 110px;
  `}

  ${breakpointQuery.xxxxlarge`
    margin-top: 120px;
  `}
`;

const StyledStatisticsInner = styled.div`
  margin-top: 20px;
  padding: 20px 0;
  text-align: center;
  background-color: ${standardColours.lighterGrey};

  ${breakpointQuery.small`
    margin-top: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
  `}

  ${breakpointQuery.large`
    margin-top: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
  `}
`;

const Statistics = ({ heading, text, items }) => {
  return (
    <StyledStatistics>
      <Animation>
        <SectionHeader heading={heading} text={text} addContainer={true} />
        <StyledStatisticsInner>
          <Container>
            {items.map(item => (
              <Statistic key={item.id} data={item} count={items.length} />
            ))}
          </Container>
        </StyledStatisticsInner>
      </Animation>
    </StyledStatistics>
  );
};

export default Statistics;
