import React from 'react';
import styled from 'styled-components';
import Animation from './Animation';
import Container from './Container';
import Heading from './Heading';
import {
  breakpointQuery,
  standardColours,
  brandColours,
  fontSize,
  visuallyHidden,
} from '../utilities';
import { commonFormStyles, ButtonInput } from './FormFields';

const StyledDemoForm = styled.section`
  background-color: ${standardColours.lighterGrey};
  padding: 40px 0;

  ${breakpointQuery.tiny`
    padding-top: 50px;
    padding-bottom: 50px;
  `}

  ${breakpointQuery.small`
    padding-top: 60px;
    padding-bottom: 60px;
  `}

  ${breakpointQuery.medium`
    padding-top: 70px;
    padding-bottom: 70px;
  `}

  ${breakpointQuery.large`
    padding-top: 80px;
    padding-bottom: 80px;
  `}
`;

const StyledDemoFormHeader = styled.header`
  margin-bottom: 20px;
  text-align: center;

  ${breakpointQuery.medium`
    margin-bottom: 25px;
  `}

  ${breakpointQuery.large`
    margin-bottom: 30px;
  `}
`;

const StyledDemoFormText = styled.p`
  margin-top: 10px;
`;

const StyledDemoFormInner = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
  margin: 0 auto;
  max-width: 800px;

  ${breakpointQuery.small`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${breakpointQuery.medium`
    grid-gap: 18px;
  `}
`;

const StyledDemoFormLabel = styled.label`
  ${({ hide }) => {
    if (hide) {
      return `
        ${visuallyHidden()}
      `;
    }
  }}

  ${({ full }) => {
    if (full) {
      breakpointQuery.small`
        grid-column: span 3;
      `;
    }
  }}
`;

const StyledDemoFormLabelText = styled.span`
  margin-left: 8px;
  ${fontSize(12)};

  ${({ hide }) => {
    if (hide) {
      return `
        ${visuallyHidden()}
      `;
    }
  }}

  a {
    color: ${brandColours.tertiary};
  }
`;

const StyledDemoFormInput = styled.input`
  ${commonFormStyles()}
`;

const StyledDemoFormInputButton = styled(ButtonInput)`
  text-transform: uppercase;

  ${breakpointQuery.small`
    grid-column: span 2;
  `}
`;

const DemoForm = ({ heading, text }) => {
  const hubspotutk =
    typeof document !== 'undefined'
      ? document.cookie.replace(
          /(?:(?:^|.*;\s*)hubspotutk\s*=\s*([^;]*).*$)|^.*$/,
          '$1'
        )
      : '';

  return (
    <StyledDemoForm>
      <Animation>
        <Container>
          <StyledDemoFormHeader>
            <Heading>{heading}</Heading>
            {text && <StyledDemoFormText>{text}</StyledDemoFormText>}
          </StyledDemoFormHeader>
          <StyledDemoFormInner
            id="demo-form"
            name="demo"
            method="POST"
            action="/demo-thanks/"
            data-netlify="true"
            netlify-honeypot="bot-field"
          >
            <input type="hidden" name="form-name" value="demo" />
            <StyledDemoFormLabel>
              <StyledDemoFormLabelText hide="true">
                First Name
              </StyledDemoFormLabelText>
              <StyledDemoFormInput
                type="text"
                name="first-name"
                placeholder="First Name"
                required
              />
            </StyledDemoFormLabel>
            <StyledDemoFormLabel>
              <StyledDemoFormLabelText hide="true">
                Last Name
              </StyledDemoFormLabelText>
              <StyledDemoFormInput
                type="text"
                name="last-name"
                placeholder="Last Name"
                required
              />
            </StyledDemoFormLabel>
            <StyledDemoFormLabel>
              <StyledDemoFormLabelText hide="true">
                Work email
              </StyledDemoFormLabelText>
              <StyledDemoFormInput
                type="email"
                name="email"
                placeholder="Work email"
                required
              />
            </StyledDemoFormLabel>
            <StyledDemoFormLabel full="true">
              <input type="checkbox" name="marketing-consent" value="true" />
              <StyledDemoFormLabelText>
                I consent to receiving occasional marketing emails from
                Barcanet. I can opt out any time. View our{' '}
                <a href="/privacy-policy/" target="_blank">
                  privacy policy
                </a>
                .
              </StyledDemoFormLabelText>
            </StyledDemoFormLabel>
            <StyledDemoFormLabel hide="true">
              <StyledDemoFormLabelText>
                Don't fill this out if you're human
              </StyledDemoFormLabelText>
              <input name="bot-field" />
            </StyledDemoFormLabel>
            <input type="hidden" name="hubspotutk" value={hubspotutk} />
            <StyledDemoFormInputButton type="submit" value="Submit" />
          </StyledDemoFormInner>
        </Container>
      </Animation>
    </StyledDemoForm>
  );
};

export default DemoForm;
