import React from 'react';
import styled from 'styled-components';
import {
  breakpointQuery,
  sectionPaddings,
  standardColours,
  brandColours,
  fontWeights,
  fontSize,
  visuallyHidden,
} from '../utilities';
import Animation from './Animation';
import SectionHeader from './SectionHeader';
import Container from './Container';
import { getAnchorId } from '../utils/AnchorId';

const StyledMiniCaseStudies = styled.section`
  ${sectionPaddings()};
  background-color: ${brandColours.senary};
`;

const StyledMiniCaseStudiesHeader = styled(SectionHeader)`
  margin-bottom: 30px;

  ${breakpointQuery.small`
    margin-bottom: 40px;
  `}

  ${breakpointQuery.large`
    margin-bottom: 50px;
  `}

  ${breakpointQuery.xxlarge`
    margin-bottom: 60px;
  `}
`;

const StyledMiniCaseStudiesItems = styled.div`
  display: grid;
  gap: 20px;

  ${breakpointQuery.small`
    gap: 25px;
  `}

  ${breakpointQuery.large`
    gap: 30px;
  `}
`;

const StyledMiniCaseStudiesItem = styled.article`
  display: grid;
  gap: 30px;
  padding: 30px;
  background-color: ${standardColours.white};
  border-radius: 20px;

  ${breakpointQuery.small`
    gap: 35px;
    padding: 35px;
  `}

  ${breakpointQuery.mlarge`
    grid-template-columns: 0.5fr 1fr 1fr 2fr;
  `}

  ${breakpointQuery.xxlarge`
    gap: 40px;
    padding: 40px;
  `}
`;

const StyledMiniCaseStudiesLogoWrapper = styled.div``;

const StyledMiniCaseStudiesHeading = styled.h3`
  ${visuallyHidden()};
`;

const StyledMiniCaseStudiesLogo = styled.img`
  width: 100%;
  max-height: 45px;
  max-width: 130px;
`;

const StyledMiniCaseStudiesContent = styled.div``;

const StyledMiniCaseStudiesSubheading = styled.h4`
  font-weight: ${fontWeights.semibold};
  ${fontSize(18)};
`;

const StyledMiniCaseStudiesText = styled.p`
  margin-top: 12px;
  line-height: 1.75;

  strong {
    font-weight: ${fontWeights.semibold};
  }
`;

const MiniCaseStudies = ({ heading, items }) => (
  <StyledMiniCaseStudies id={getAnchorId(heading)}>
    <Animation>
      <StyledMiniCaseStudiesHeader heading={heading} />
      <Container>
        <StyledMiniCaseStudiesItems>
          {items.map(({ heading, logo, challenge, solution, results }, id) => {
            const content = [
              {
                subheading: 'Challenge',
                text: challenge,
              },
              {
                subheading: 'Solution',
                text: solution,
              },
              {
                subheading: 'Results',
                text: results,
              },
            ];

            return (
              <StyledMiniCaseStudiesItem key={id}>
                <StyledMiniCaseStudiesLogoWrapper>
                  <StyledMiniCaseStudiesHeading>
                    {heading}
                  </StyledMiniCaseStudiesHeading>
                  <StyledMiniCaseStudiesLogo
                    src={logo.url}
                    alt={logo.alt}
                    loading="lazy"
                  />
                </StyledMiniCaseStudiesLogoWrapper>
                {content.map(({ subheading, text }, id) => (
                  <StyledMiniCaseStudiesContent key={id}>
                    <StyledMiniCaseStudiesSubheading>
                      {subheading}
                    </StyledMiniCaseStudiesSubheading>
                    <StyledMiniCaseStudiesText
                      dangerouslySetInnerHTML={{ __html: text }}
                    />
                  </StyledMiniCaseStudiesContent>
                ))}
              </StyledMiniCaseStudiesItem>
            );
          })}
        </StyledMiniCaseStudiesItems>
      </Container>
    </Animation>
  </StyledMiniCaseStudies>
);

export default MiniCaseStudies;
