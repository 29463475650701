import React from 'react';
import styled from 'styled-components';
import {
  breakpointQuery,
  brandColours,
  brandFonts,
  fontWeights,
  fontSize,
  sectionMargins,
} from '../utilities';
import Animation from './Animation';
import Container from './Container';

const StyledTestimonial = styled.section`
  ${sectionMargins()};
`;

const StyledTestimonialInner = styled.div`
  text-align: center;

  &:before {
    content: '“”';
    display: block;
    margin-bottom: 14px;
    height: 22px;
    color: ${brandColours.tertiary};
    font-family: ${brandFonts.secondary};
    ${fontSize(40)}
    line-height: 1;
    letter-spacing: 6px;

    ${breakpointQuery.small`
      margin-bottom: 20px;
      height: 30px;
      ${fontSize(54)}
      letter-spacing: 8px;
    `}

    ${breakpointQuery.large`
      margin-bottom: 26px;
      height: 38px;
      ${fontSize(68)}
      letter-spacing: 10px;
    `}
  }
`;

const StyledTestimonialQuote = styled.p`
  ${fontSize(18)}
  line-height: 1.7;
  

  ${breakpointQuery.tiny`
    ${fontSize(20)}
  `}

  ${breakpointQuery.medium`
    ${fontSize(22)}
  `}

  ${breakpointQuery.large`
    ${fontSize(24)}
  `}
`;

const StyledTestimonialAuthor = styled.footer`
  margin-top: 20px;
  font-weight: ${fontWeights.medium};

  ${breakpointQuery.medium`
    margin-top: 30px;
    ${fontSize(18)}
  `}
`;

const Testimonial = ({ quote, author }) => {
  return (
    <StyledTestimonial>
      <Animation>
        <Container narrow={true}>
          <StyledTestimonialInner>
            <StyledTestimonialQuote>{quote}</StyledTestimonialQuote>
            <StyledTestimonialAuthor>{author}</StyledTestimonialAuthor>
          </StyledTestimonialInner>
        </Container>
      </Animation>
    </StyledTestimonial>
  );
};

export default Testimonial;
