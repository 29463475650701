import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import {
  breakpointQuery,
  sectionPaddings,
  standardColours,
  brandColours,
  fontWeights,
} from '../utilities';
import Animation from './Animation';
import SectionHeader from './SectionHeader';
import Container from './Container';
import { PopupContactButton } from './PopupContact';
import { getAnchorId } from '../utils/AnchorId';
import quoteMarks from '../images/quote-marks.svg';

const StyledTestimonials = styled.section`
  ${sectionPaddings()};
  background-color: ${brandColours.tertiary};
`;

const StyledTestimonialsHeader = styled(SectionHeader)`
  margin-bottom: 30px;
  color: ${standardColours.white};

  ${breakpointQuery.small`
    margin-bottom: 40px;
  `}

  ${breakpointQuery.large`
    margin-bottom: 50px;
  `}

  ${breakpointQuery.xxlarge`
    margin-bottom: 60px;
  `}
`;

const StyledTestimonialsItems = styled.div`
  display: grid;
  gap: 20px;

  ${breakpointQuery.medium`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${breakpointQuery.large`
    gap: 30px;
  `}
`;

const StyledTestimonialsItem = styled.figure`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  padding: 30px;
  background-color: ${standardColours.white};
  border-radius: 20px;
`;

const StyledTestimonialsQuote = styled.blockquote`
  margin: 0;
  padding-top: 40px;
  background: url(${quoteMarks}) no-repeat top left / 30px 22px;
`;

const StyledTestimonialsText = styled.p`
  line-height: 1.75;
`;

const StyledTestimonialsMeta = styled.figcaption`
  display: flex;
  gap: 15px;
  margin-top: 18px;
`;

const StyledTestimonialsImage = styled(Img)`
  min-width: 46px;
  border-radius: 50%;
  border: 2px solid ${brandColours.quaternary};
`;

const StyledTestimonialsAuthorBio = styled.div``;

const StyledTestimonialsAuthor = styled.span`
  font-weight: ${fontWeights.medium};
`;

const StyledTestimonialsBio = styled.span`
  display: block;
  margin-top: 5px;
  line-height: 1.5;
`;

const StyledTestimonialsFooter = styled.footer`
  margin-top: 30px;
  text-align: center;

  ${breakpointQuery.small`
    margin-top: 35px;
  `}

  ${breakpointQuery.large`
    margin-top: 40px;
  `}

  ${breakpointQuery.xxlarge`
    margin-top: 45px;
  `}
`;

const Testimonials = ({ heading, items, buttonText, isCampaignPage }) => (
  <StyledTestimonials id={getAnchorId(heading)}>
    <Animation>
      <StyledTestimonialsHeader heading={heading} whiteLine={true} />
      <Container>
        <StyledTestimonialsItems>
          {items.map(({ quote, author, bio, image }, id) => (
            <StyledTestimonialsItem key={id}>
              <StyledTestimonialsQuote>
                <StyledTestimonialsText>{quote}</StyledTestimonialsText>
              </StyledTestimonialsQuote>
              <StyledTestimonialsMeta>
                <StyledTestimonialsImage fixed={image.fixed} alt={image.alt} />
                <StyledTestimonialsAuthorBio>
                  <StyledTestimonialsAuthor>{author}</StyledTestimonialsAuthor>
                  {bio && <StyledTestimonialsBio>{bio}</StyledTestimonialsBio>}
                </StyledTestimonialsAuthorBio>
              </StyledTestimonialsMeta>
            </StyledTestimonialsItem>
          ))}
        </StyledTestimonialsItems>
        {isCampaignPage && (
          <StyledTestimonialsFooter>
            <PopupContactButton buttonText={buttonText} />
          </StyledTestimonialsFooter>
        )}
      </Container>
    </Animation>
  </StyledTestimonials>
);

export default Testimonials;
