import React from 'react';
import styled from 'styled-components';
import { breakpointQuery, fontSize, sectionMargins } from '../utilities';
import Animation from './Animation';
import SectionHeader from './SectionHeader';
import Container from './Container';
import FixedImg from './FixedImg';
import { getAnchorId } from '../utils/AnchorId';

const StyledImage = styled.section`
  ${sectionMargins()};
`;

const StyledImageHeader = styled(SectionHeader)`
  margin-bottom: 30px;

  ${breakpointQuery.small`
    margin-bottom: 40px;
  `}

  ${breakpointQuery.large`
    margin-bottom: 50px;
  `}

  ${breakpointQuery.xxlarge`
    margin-bottom: 60px;
  `}
`;

const StyledImageText = styled.p`
  margin-top: 16px;
  line-height: 1.7;
  text-align: center;

  ${breakpointQuery.small`
    margin-top: 18px;
    ${fontSize(17)};
  `}

  ${breakpointQuery.large`
    margin-top: 20px;
    ${fontSize(18)};
  `}
`;

const Image = ({ heading, image, text, isLarge }) => (
  <StyledImage
    {...(heading && {
      id: getAnchorId(heading),
    })}
  >
    <Animation>
      {heading && <StyledImageHeader heading={heading} />}
      <Container narrow={!isLarge}>
        <FixedImg image={image} />
        {text && <StyledImageText>{text}</StyledImageText>}
      </Container>
    </Animation>
  </StyledImage>
);

export default Image;
