import React from 'react';
import styled from 'styled-components';
import {
  breakpointQuery,
  standardColours,
  fontWeights,
  fontSize,
} from '../utilities';
import Animation from './Animation';
import Container from './Container';
import gartnerCoolVendor2020 from '../images/gartner-cool-vendor-2020.png';

const StyledGartnerContent = styled.section``;

const StyledGartnerContentOuter = styled.div`
  padding: 25px 0;
  background-color: ${standardColours.lighterGrey};
`;

const StyledGartnerContentInner = styled.div`
  text-align: center;

  ${breakpointQuery.tiny`
    display: flex;
    align-items: center;
    gap: 25px;
    text-align: initial;
  `}
`;

const StyledGartnerContentImage = styled.img`
  width: 80px;
`;

const StyledGartnerContentText = styled.p`
  margin-top: 20px;
  font-weight: ${fontWeights.medium};
  ${fontSize(14)};
  line-height: 1.7;

  ${breakpointQuery.tiny`
    margin-top: 0;
  `}

  ${breakpointQuery.small`
    ${fontSize(15)};
  `}

  ${breakpointQuery.large`
    ${fontSize(16)};
  `}
`;

const GartnerContent = ({ text }) => (
  <StyledGartnerContent>
    <Animation>
      <StyledGartnerContentOuter>
        <Container narrow={true}>
          <StyledGartnerContentInner>
            <StyledGartnerContentImage
              src={gartnerCoolVendor2020}
              alt="Gartner Cool Vendor 2020"
              loading="lazy"
            />
            <StyledGartnerContentText>{text}</StyledGartnerContentText>
          </StyledGartnerContentInner>
        </Container>
      </StyledGartnerContentOuter>
    </Animation>
  </StyledGartnerContent>
);

export default GartnerContent;
