import React from 'react';
import styled from 'styled-components';
import {
  breakpointQuery,
  sectionMargins,
  standardColours,
  fontSize,
} from '../utilities';
import Animation from './Animation';
import SectionHeader from './SectionHeader';
import Container from './Container';
import { PopupContactButton } from './PopupContact';
import { getAnchorId } from '../utils/AnchorId';
import arrow from '../images/arrow.svg';

const StyledFeaturedSteps = styled.section`
  ${sectionMargins()};
`;

const StyledFeaturedStepsHeader = styled(SectionHeader)`
  margin-bottom: 30px;

  ${breakpointQuery.small`
    margin-bottom: 40px;
  `}

  ${breakpointQuery.large`
    margin-bottom: 50px;
  `}

  ${breakpointQuery.xxlarge`
    margin-bottom: 60px;
  `}
`;

const StyledFeaturedStepsItems = styled.ol`
  display: grid;
  gap: 40px;

  ${breakpointQuery.medium`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${breakpointQuery.large`
    gap: 50px;
  `}

  ${breakpointQuery.xxlarge`
    gap: 60px;
  `}
`;

const StyledFeaturedStepsItem = styled.li`
  position: relative;

  &:not(:last-child) {
    &:after {
      content: '';
      position: absolute;
      top: calc(100% + 15px);
      left: 50%;
      height: 15px;
      width: 30px;
      background: url(${arrow}) no-repeat 50% 50% / contain;
      transform: translateX(-50%) rotate(90deg);

      ${breakpointQuery.medium`
        top: 60px;
        left: calc(100% + 5px);
        transform: none;
      `}

      ${breakpointQuery.large`
        left: calc(100% + 10px);
      `}

      ${breakpointQuery.xxlarge`
        left: calc(100% + 15px);
      `}
    }
  }
`;

const StyledFeaturedStepsItemInner = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
  background-color: ${standardColours.white};
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 12px 0;
  overflow: hidden;
`;

const StyledFeaturedStepsItemTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 30px;
  height: 140px;
  background-color: ${({ backgroundColour }) =>
    backgroundColour ? backgroundColour.hex : standardColours.darkerGrey};
`;

const StyledFeaturedStepsItemText = styled.p`
  color: ${standardColours.white};
  ${fontSize(20)};
  white-space: break-spaces;
`;

const StyledFeaturedStepsItemImage = styled.img`
  margin: auto;
  padding: 30px;
  width: 100%;
  max-height: 260px;
  max-width: 340px;
`;

const StyledFeaturedStepsText = styled.p`
  margin-top: 22px;
  line-height: 1.7;
  text-align: center;

  ${breakpointQuery.small`
    margin-top: 25px;
    ${fontSize(17)};
  `}

  ${breakpointQuery.large`
    margin-top: 28px;
    ${fontSize(18)};
  `}
`;

const StyledFeaturedStepsFooter = styled.footer`
  margin-top: 20px;
  text-align: center;

  ${breakpointQuery.small`
    margin-top: 25px;
  `}

  ${breakpointQuery.large`
    margin-top: 30px;
  `}
`;

const FeaturedSteps = ({
  heading,
  items,
  text,
  buttonText,
  isCampaignPage,
}) => (
  <StyledFeaturedSteps id={getAnchorId(heading)}>
    <Animation>
      <StyledFeaturedStepsHeader heading={heading} />
      <Container>
        <StyledFeaturedStepsItems>
          {items.map(({ text, textBackgroundColour, image }, id) => (
            <StyledFeaturedStepsItem key={id}>
              <StyledFeaturedStepsItemInner>
                <StyledFeaturedStepsItemTextWrapper
                  backgroundColour={textBackgroundColour}
                >
                  <StyledFeaturedStepsItemText>
                    {text}
                  </StyledFeaturedStepsItemText>
                </StyledFeaturedStepsItemTextWrapper>
                <StyledFeaturedStepsItemImage src={image.url} alt={image.alt} />
              </StyledFeaturedStepsItemInner>
            </StyledFeaturedStepsItem>
          ))}
        </StyledFeaturedStepsItems>
        {text && <StyledFeaturedStepsText>{text}</StyledFeaturedStepsText>}
        {isCampaignPage && (
          <StyledFeaturedStepsFooter>
            <PopupContactButton buttonText={buttonText} />
          </StyledFeaturedStepsFooter>
        )}
      </Container>
    </Animation>
  </StyledFeaturedSteps>
);

export default FeaturedSteps;
