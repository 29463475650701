import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styled from 'styled-components';
import { breakpointQuery, sectionMargins, standardColours } from '../utilities';
import Animation from './Animation';
import Container from './Container';

const StyledFeaturedLogos = styled.section`
  ${sectionMargins()};
`;

const StyledFeaturedLogosInner = styled.div`
  position: relative;
  padding: 20px 45px;
  background-color: ${standardColours.white};
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 12px 0;

  ${breakpointQuery.large`
    padding-left: 60px;
    padding-right: 60px;
  `}
`;

const StyledFeaturedLogosSlider = styled(Slider)`
  position: static;
  width: 100%;

  .slick-slide {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .slick-arrow {
    width: auto;
    height: auto;

    &:before {
      content: '';
      display: block;
      padding: 7px;
      border: solid ${standardColours.black};
      border-width: 0 3px 3px 0;
      border-radius: 2px;
    }
  }

  .slick-next {
    right: 18px;

    ${breakpointQuery.large`
      right: 26px;
    `}

    &:before {
      transform: rotate(-45deg);
    }
  }

  .slick-prev {
    left: 18px;

    ${breakpointQuery.large`
      left: 26px;
    `}

    &:before {
      transform: rotate(135deg);
    }
  }
`;

const StyledFeaturedLogosImage = styled.img`
  padding: 10px;
  height: 60px;
  max-width: 180px;
`;

const FeaturedLogos = ({ items }) => {
  const settings = {
    autoplay: true,
    slidesToShow: 6,
    speed: 300,
    responsive: [
      {
        breakpoint: 1260,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <StyledFeaturedLogos>
      <Animation>
        <Container>
          <StyledFeaturedLogosInner>
            <StyledFeaturedLogosSlider {...settings}>
              {items.map(({ url, alt }, i) => (
                <StyledFeaturedLogosImage
                  key={`logo-${i}`}
                  src={url}
                  loading="lazy"
                  alt={alt}
                />
              ))}
            </StyledFeaturedLogosSlider>
          </StyledFeaturedLogosInner>
        </Container>
      </Animation>
    </StyledFeaturedLogos>
  );
};

export default FeaturedLogos;
